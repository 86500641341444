import { Link } from 'react-router-dom';

export default () => {
	return (
		<div className='w-full h-[400vh] flex flex-col justify-between items-center my-32 p-5'>
			<div className='h-full flex flex-col justify-between items-center'>
				<h1>
					<span className='text-6xl sm:text-8xl font-bld'>
						Whoops I don't think you meant to go here...
					</span>
					<br />
					<span className='text-4xl sm:text-6xl font-bld'>
						It's empty.
					</span>
					<br />
					<span className='text-2xl sm:text-4xl font-bld'>
						Really
					</span>
					<br />
					<span className='text-xl sm:text-2xl font-bld'>Really</span>
					<br />
					<span className='sm:text-xl font-bld'>Empty</span>
					<br />
					<br />
					<span className='text-xl'>
						How about going back to the{' '}
						<Link className='underline' to='/'>
							home page?
						</Link>
					</span>
				</h1>
				<p className='text-2xl font-bld'>
					Don't even bother scrolling. There's nothing there.
				</p>
			</div>
			<p className='text-4xl flex flex-row items-center gap-5'>
				<span>
					Did you really try to see what's at the bottom of the page?
					We'll as I said before: It's quite empty. Here's a cookie
					for your troubles:
				</span>
				<span className='text-8xl transition-all scale-100 active:scale-125'>
					🍪
				</span>
			</p>
		</div>
	);
};
