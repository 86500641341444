import LazyLoadedImage from 'Components/shared/LazyLoadedImage';
import { getMetadata } from 'modules/utils/metadata';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { images, lowresImages, collections } from 'modules/images';

export default class extends Component {
	constructor() {
		super();

		this.state = {
			metadata: {},
		};
	}

	fetchMetadata = async () => {
		const metadata = await getMetadata();

		this.setState({ metadata, loaded: true });
	};

	componentDidMount() {
		this.fetchMetadata();
	}

	render() {
		const { metadata, loaded } = this.state;

		if (!loaded) {
			return;
		}

		const imagesMap = [];

		for (const collection of collections) {
			const collectionImages = images[collection];
			const collectionLowresImages = lowresImages[collection];
			const collectionMetadata = metadata[collection];
			const path = `./${collectionMetadata?.coverImage}`;

			const fileName = path.split('/').pop();

			const img = collectionImages(path);
			const lowresImg = collectionLowresImages(`./${fileName}`);

			imagesMap.push(
				<Link
					to={`/photo-collection/${collection}`}
					className={
						collectionMetadata[fileName]?.orientation ===
						'landscape'
							? 'col-[span_3_/_auto]'
							: ''
					}>
					<LazyLoadedImage
						key={path}
						width={collectionMetadata[fileName]?.width}
						height={collectionMetadata[fileName]?.height}
						src={img}
						className={`w-full h-full ${
							collectionMetadata[fileName]?.fit === false
								? ''
								: 'object-cover'
						}`}
						alt={path}
						placeholderSrc={lowresImg}
					/>
				</Link>
			);
		}

		return (
			<div className='grid grid-cols-3 gap-[25px] auto-rows-auto grid-flow-dense p-5'>
				{imagesMap}
			</div>
		);
	}
}
