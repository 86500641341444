import PhotoGallery from 'Components/containers/PhotoGallery';
import { createRef, useEffect } from 'react';

const textList = [
	['software', 'engineer.'],
	['automotive', 'photographer.'],
	['web', 'designer.'],
	['digital', 'photographer.'],
	['graphic', 'designer.'],
];
export default () => {
	const subtext1Ref = createRef();
	const subtext2Ref = createRef();

	useEffect(() => {
		let currentSubtextIndex = 0;

		const interval = setInterval(() => {
			currentSubtextIndex++;

			if (currentSubtextIndex >= textList.length) {
				currentSubtextIndex = 0;
			}

			const currentSubtext = textList[currentSubtextIndex];

			const subtext1 = subtext1Ref.current;
			if (!subtext1) return;

			subtext1Ref.current.innerText = currentSubtext[0];

			setTimeout(() => {
				const subtext2 = subtext2Ref.current;
				if (!subtext2) return;

				subtext2.innerText = currentSubtext[1];
			}, 1000);
		}, 5000 * 0.2 + 4000);

		return () => clearInterval(interval);
	}, []);

	return (
		<div className='w-full h-full text-center mb-5'>
			<div className='overflow-y-hidden'>
				<div className='animate-swipe-up-initial translate-y-[200%] opacity-0'>
					<svg viewBox='0 0 90 18' className='w-full px-5'>
						<text x='0' y='15' className='sm:font-bold'>
							CADEN MARINOZZI
						</text>
					</svg>
				</div>
			</div>
			<p className='gap-5 sm:gap-0 w-full flex flex-row justify-between px-5 uppercase text-xl sm:text-4xl font-bld'>
				<span>Your</span>
				<span className='sm:w-full '>next</span>
				<div className='w-full flex flex-row'>
					<div className='w-full gap-5 flex flex-row justify-between overflow-y-hidden'>
						<span className='animate-swipe-up' ref={subtext1Ref}>
							software
						</span>
						<span
							className='animate-swipe-up-delayed'
							ref={subtext2Ref}>
							engineer.
						</span>
					</div>
				</div>
			</p>
			<PhotoGallery />
		</div>
	);
};
