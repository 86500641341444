export const getMetadata = async () => {
	const response = await fetch('/metadata.json', {
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	});

	const metadata = await response.json();

	return metadata;
};
