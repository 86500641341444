import LazyLoadedImage from 'Components/shared/LazyLoadedImage';
import { getMetadata } from 'modules/utils/metadata';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { images, lowresImages } from 'modules/images';

export default () => {
	const [metadata, setMetadata] = useState({});
	const [loaded, setLoaded] = useState(false);

	const fetchMetadata = async () => {
		const metadata = await getMetadata();

		setMetadata(metadata);
		setLoaded(true);
	};

	useEffect(() => {
		fetchMetadata();
	}, []);

	if (!loaded) {
		return;
	}

	const { collectionId } = useParams();
	const collectionImages = images[collectionId];
	const collectionLowresImages = lowresImages[collectionId];
	const collectionMetadata = metadata[collectionId];

	const imagesMap = collectionImages.keys().map((path) => {
		if (!path.includes('.jpg') && !path.includes('.png')) return;

		const fileName = path.split('/').pop();
		const img = collectionImages(path);
		const lowresImg = collectionLowresImages(`./${fileName}`);
		const imageMetadata = collectionMetadata[fileName];

		return (
			<Link
				to={`/photo-view/${collectionId}/${fileName}`}
				className={
					imageMetadata?.orientation === 'landscape'
						? 'col-[span_3_/_auto]'
						: ''
				}>
				<LazyLoadedImage
					key={path}
					src={img}
					width={imageMetadata?.width}
					height={imageMetadata?.height}
					className={`w-full h-full ${
						imageMetadata?.fit === false ? '' : 'object-cover'
					}`}
					alt={path}
					placeholderSrc={lowresImg}
				/>
			</Link>
		);
	});

	return (
		<div className='h-full w-full flex flex-col gap-5 place-items-center p-5'>
			<div className='flex flex-col gap-2 place-items-center'>
				<span className='text-xl'>{collectionMetadata.date}</span>
				<span className='text-xl'>
					{collectionMetadata.description}
				</span>
			</div>
			<div className='grid grid-cols-3 gap-[25px] auto-rows-auto grid-flow-dense pb-5'>
				{imagesMap}
			</div>
		</div>
	);
};
