import GitHubImage from 'assets/images/contacts/GitHub.jpg';
import LinkedInImage from 'assets/images/contacts/Linkedin.jpg';
import MediumImage from 'assets/images/contacts/Medium.jpg';

const contacts = [
	{
		link: 'https://github.com/cadenmarinozzi',
		name: 'GitHub',
		image: GitHubImage,
	},
	{
		link: 'https://linkedin.com/in/caden-m',
		name: 'LinkedIn',
		image: LinkedInImage,
	},
	{
		link: 'https://medium.com/@cadenmarinozzi',
		name: 'Medium',
		image: MediumImage,
	},
];

export default () => {
	const contactsMap = contacts.map((contact) => (
		<a
			href={contact.link}
			className='flex flex-col gap-10 group pointer'
			target='_blank'
			rel='noreferrer noopener'>
			<img
				src={contact.image}
				alt={contact.name}
				className='w-full h-full rounded-md transition-all duration-300 border object-cover group-hover:border-transparent group-hover:shadow-lg border-black'
			/>
			<span className='uppercase text-2xl'>{contact.name}</span>
		</a>
	));

	return (
		<div className='w-full h-full text-center'>
			<div className='w-full p-5 overflow-y-hidden overflow-x-hidden flex flex-col gap-10 sm:gap-0'>
				<div className='animate-swipe-up-initial translate-y-[200%] opacity-0 flex flex-row gap-5 sm:gap-10 items-center content-center justify-between uppercase flex-wrap'>
					<h1 className=' text-8xl md:text-[12rem] sm:text-[20rem] font-bld'>
						LETS
					</h1>
					<svg
						viewBox='0 0 10 18'
						xmlns='http://www.w3.org/2000/svg'
						width='300'
						height='350'
						className='h-full animate-rotate-up-in rotate-[180deg] scale-[75%] opacity-0 hidden sm:block'>
						<path d='m2.828 15.555 7.777-7.779L2.828 0 0 2.828l4.949 4.948L0 12.727l2.828 2.828z' />
					</svg>
					<h1 className='text-8xl md:text-[12rem] sm:text-[20rem] font-bld'>
						CONNECT
					</h1>
				</div>
			</div>
			<div className='flex flex-col gap-32  mx-5'>
				<div className='overflow-y-hidden'>
					<div className='grid grid-cols-1 sm:grid-cols-3 gap-10 animate-swipe-up-initial translate-y-[100%] opacity-0'>
						{contactsMap}
					</div>
				</div>
				<div className='group flex flex-col gap-5 m-5'>
					<a
						href='mailto:cadenmarinozzi@gmail.com'
						className='text-8xl uppercase font-bld'>
						SEND ME AN EMAIL
					</a>
					<span className='text-2xl sm:opacity-0 transition-all group-hover:opacity-100'>
						cadenmarinozzi@gmail.com
					</span>
				</div>
			</div>
		</div>
	);
};
